import { PageProps } from 'gatsby'
import React from "react"
import WritingPage from "../../components/Writing"

const WritingPageWrapper = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <WritingPage writingId={props.params.writingId} />
}

export default WritingPageWrapper
